import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import { Box } from '@workday/canvas-kit-react/layout';
import { space, colors } from '@workday/canvas-kit-react/tokens';
import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { StatusIndicator } from '@workday/canvas-kit-react/status-indicator';
import { SpaceUsageTabs } from '../../../src/components/content/tokens/SpaceUsageTabs';
import { SpaceTokens } from '../../../src/components/content/tokens/TokenSpaceGrid';
import { getInternalExternalURL } from '../../../src/utils/url';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PackageInfo = makeShortcode("PackageInfo");
const Do = makeShortcode("Do");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InformationHighlight mdxType="InformationHighlight">
  <InformationHighlight.Icon style={{
        width: '24px'
      }} />
  <InformationHighlight.Heading>Looking for our old space tokens?</InformationHighlight.Heading>
  <InformationHighlight.Body>
    While we still support our old tokens, we’re migrating our documentation and usage guidance to
    reflect our new Tokens Web. It is <strong>not</strong> currently a requirement to update all
    your tokens to use v11, but we <strong>strongly</strong> encourage you to begin migrating as you
    can. If you're an engineer looking to migrate to our new tokens, we wrote a handy{' '}
    <ExternalHyperlink href={'https://github.com/Workday/canvas-tokens/discussions/77'} mdxType="ExternalHyperlink">
      migration guide
    </ExternalHyperlink>
    for you.
  </InformationHighlight.Body>
  <InformationHighlight.Link href={getInternalExternalURL('/v10/tokens/space')}>
    View v10 Space Token Docs.
  </InformationHighlight.Link>
    </InformationHighlight>
    <PackageInfo storybook="https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-space--docs" figma="https://www.figma.com/design/GmIqhQ7KqX6WQZFimdn756/Canvas-Tokens-v2?node-id=21702-31392&t=zaRL8ALlvmcTsWQc-4" package="@workday/canvas-tokens-web" mdxType="PackageInfo" />
    <h2 {...{
      "id": "usage-guidance"
    }}>{`Usage Guidance`}</h2>
    <p>{`Spacing is defined by multipliers varying from x1 to x20 with a base number (x) of four.`}</p>
    <p>{`Our spacing guidelines are based on the principles of visual design. We recommend using these
guidelines to provide a consistent visual experience across your product.`}</p>
    <p>{`Before making decisions on how to use our space tokens in your product it is good to know the
rationale behind our spacing system. The following section outlines this.`}</p>
    <h3 {...{
      "id": "4-unit-base"
    }}>{`4-unit base`}</h3>
    <p>{`Using a 4-unit base multiplier for space tokens enhances consistency, scalability, and precision. It
aligns with industry standards, simplifies calculations, and reduces cognitive load, making the
design and development process more efficient and adaptable.`}</p>
    <h3 {...{
      "id": "8pt-grid-system"
    }}>{`8pt grid system`}</h3>
    <p>{`At Workday we use a systematic approach to how we decide what spacing to use in order to achieve a
consistent visual experience across our products. We achieve this by using a spacing system. At
Workday we follow the
`}<a parentName="p" {...{
        "href": "https://medium.com/swlh/the-comprehensive-8pt-grid-guide-aa16ff402179"
      }}>{`8pt Grid System`}</a>{`.`}</p>
    <p>{`The main benefits of using an 8pt grid system are:`}</p>
    <Do hideHeading={true} mdxType="Do">
      <ul>
        <li parentName="ul">{`Flexible and consistent to work with while offering enough visual distinction between steps.`}</li>
        <li parentName="ul">{`Scales well, avoiding blurred images caused by half pixel rendering on some screen densities.`}</li>
      </ul>
    </Do>
    <h2 {...{
      "id": "canvas-space-tokens"
    }}>{`Canvas Space Tokens`}</h2>
    <p>{`This section outlines the current space tokens that we support. Our space tokens include the current
spacing values in Pixels. Use these space tokens when positioning Canvas components in your product.`}</p>
    <p>{`We have aligned our base space size to our `}<a parentName="p" {...{
        "href": "./type"
      }}>{`base font size`}</a>{` `}<strong parentName="p">{`(16px)`}</strong>{` to help you to space
elements in your product relative to our type scale. This will help your overall layout to scale
proportionality.`}</p>
    <h3 {...{
      "id": "recommended-usage"
    }}>{`Recommended Usage`}</h3>
    <p>{`Choose from the space tokens below to see their recommended usage.`}</p>
    <SpaceUsageTabs mdxType="SpaceUsageTabs" />
    <p>{`The table below displays all the current space token names for your reference, along with their
equivalent pixel values. Space variables are in multiples of four. Space values are in `}<inlineCode parentName="p">{`px`}</inlineCode>{` format
(`}<inlineCode parentName="p">{`space`}</inlineCode>{` object) or number format (`}<inlineCode parentName="p">{`spaceNumbers`}</inlineCode>{` object).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Token Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size (px)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size (number)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Space`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zero`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0px`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.zero} height={space.zero} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'4px'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.xxxs} height={space.xxxs} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'8px'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.xxs} height={space.xxs} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'12px'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.xs} height={space.xs} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><inlineCode parentName="strong">{`x4`}</inlineCode></strong>{` `}<StatusIndicator type={StatusIndicator.Type.Green} label="Base Value" style={{
              marginLeft: space.s
            }} mdxType="StatusIndicator" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><inlineCode parentName="strong">{`'16px'`}</inlineCode></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><inlineCode parentName="strong">{`16`}</inlineCode></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.s} height={space.s} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'24px'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`24`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.m} height={space.m} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'32px'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`32`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.l} height={space.l} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'40px'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`40`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.xl} height={space.xl} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x14`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'64px'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`64`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.xxl} height={space.xxl} mdxType="Box" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x20`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'80px'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`80`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><Box backgroundColor={colors.grapeSoda300} style={{
              opacity: 0.5
            }} width={space.x20} height={space.xxxl} mdxType="Box" /></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "web-examples"
    }}>{`Web Examples`}</h2>
    <h3 {...{
      "id": "javascript--typescript"
    }}>{`Javascript / Typescript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// styles.ts
import {system} from '@workday/canvas-tokens-web';

const styles = {
  display: 'grid',
  gap: \`var(\${system.space.x1})\`,
  padding: \`var(\${system.space.x2})\`,
};
`}</code></pre>
    <h3 {...{
      "id": "css"
    }}>{`CSS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// styles.css
@import '@workday/canvas-tokens-web/css/system/_variables.css';
.card {
  display: grid;
  gap: var(--cnvs-sys-space-x1);
  padding: var(--cnvs-sys-space-x2);
}
`}</code></pre>
    <SpaceTokens mdxType="SpaceTokens" />
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "ios-examples"
      }}>{`iOS Examples`}</h2>
      <p>{`Coming soon!`}</p>
      <h2 {...{
        "id": "android-examples"
      }}>{`Android Examples`}</h2>
      <p>{`Coming soon!`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      